/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import './App.css';
import listaZawodow from './modules/obsluga/ZawodyEditList';
import detaleZawodow from './modules/obsluga/ZawodyDetale';
import dashBoard from './modules/statystyki/DashBoard';
import Top10 from './modules/statystyki/top10';
import Top10Junior from './modules/statystyki/top10Junior';
import Top10JuniorMlodszy from './modules/statystyki/top10JuniorMlodszy';
import Top10Mlodzik from './modules/statystyki/top10Mlodzik';
import sredniaSerii from './modules/statystyki/SredniaSerii';
import porownaj from './modules/statystyki/Porownaj';
import wyszukaj from './modules/statystyki/Wyszukaj';
import zawodnik from './modules/statystyki/Zawodnik';
import zawody from './modules/statystyki/Zawody';
import klub from './modules/statystyki/Klub';
import zawodyList from './modules/statystyki/ZawodyList';
import liczby from './modules/statystyki/Liczby';
import narzedzia from './modules/statystyki/Narzedzia';
import Layout from './Layout';
import { Route, Switch } from 'react-router';
import 'bootstrap/dist/css/bootstrap.css';
import { PageNotFound } from './modules/PageNotFound';
import MvpJunMl from './modules/statystyki/MvpJunMl';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-XJPXQGS3KH');
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

export default (): ReactElement => {
    return (
        <Layout>
            <Switch>
                <Route path="/zawodyEditList" component={listaZawodow} />
                <Route path="/zawodyDetale/:id" component={detaleZawodow} />
                <Route path="/zawodnik/:id" component={zawodnik} />
                <Route path="/zawody/:id" component={zawody} />
                <Route path="/klub/:id" component={klub} />
                <Route path="/zawodyList" component={zawodyList} />
                <Route path="/sredniaSerii" component={sredniaSerii} />
                <Route path="/porownaj/:zawodnik1/:zawodnik2" component={porownaj} />
                <Route path="/wyszukaj" component={wyszukaj} />
                <Route path="/liczby" component={liczby} />
                <Route path="/narzedzia" component={narzedzia} />
                <Route path="/top10" component={Top10} />
                <Route path="/top10junior" component={Top10Junior} />
                <Route path="/top10juniorMl" component={Top10JuniorMlodszy} />
                <Route path="/top10mlodzik" component={Top10Mlodzik} />
                <Route path="/mvpJunMl" component={MvpJunMl} />
                <Route component={dashBoard} />
            </Switch>
        </Layout>
    );
};
