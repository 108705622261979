import { IZawodyModel } from './model/ZawodyModel';
import BaseClient from '../BaseClient';
import IGetZawodyResponse from './model/ZawodyResponse';
import ISaveZawodyResponse from './model/SaveZawodyResponse';
import { IWynikSaveModel } from './model/WynikSaveModel';
import ISaveWynikiResponse from './model/SaveWynikResponse';
import IGetWynikiResponse from './model/GetWynikiResponse';
import { createNumericLiteral } from 'typescript';
import IGetSredniaResponse from './model/GetSredniaResponse';
import { ISredniaData } from '../../modules/statystyki/SredniaSerii';
import IZawodnikModel from './model/ZawodnikModel';
import SrednieByZawodnikModel from './model/SrednieByZawodnikModel';
import { ISredniaByZawodnikData } from '../../modules/statystyki/Zawodnik';
import IGetBestOfTheYear from './model/GetBestOfTheYearResult';
import NajlepszeByZawodnikModel from './model/NajlepszeByZawodnikModel';
import IGetZawodnicyPerKlubResult from './model/GetZawodnicyPerKlubResult';
import IGetStatNumberResult from './model/GetStatNumberResult';
import IGetZawodnicyKlub from './model/GetZawodnicyKlub';
import IZawodnikImageModel from './model/ImagesModel';
import { IKlubData } from '../../modules/statystyki/Klub';
import { IMvpDataRow } from './model/GetMvpResponse';
import IGetMvpRequest from './model/GetMvpRequest';

// import IGetComboResponse from './model/GetComboResponse';
// import { IOrganizationUnit } from '../../modules/ou/models/OrganizationUnit';
// import ICheckMasterOuResponse from './model/CheckMasterOuResponse';

export interface IZawodyClient {
    getAllZawody(): Promise<IZawodyModel[]>;
    getZawody(id: number): Promise<IZawodyModel>;
    createZawody(zawody: IZawodyModel): Promise<IZawodyModel>;
    updateZawody(zawody: any): Promise<IZawodyModel>;
    saveCsv(wyniki: IWynikSaveModel): Promise<ISaveWynikiResponse>;
    getWynikiByZawody(id: number): Promise<IGetWynikiResponse[]>;
    getSredniaSeriaByKonkurencja(data: ISredniaData): Promise<IGetSredniaResponse[]>;
    getZawodnik(id: number): Promise<IZawodnikModel>;
    getSredniaByZawodnik(data: ISredniaByZawodnikData): Promise<SrednieByZawodnikModel[]>;
    getNajlepszeByZawodnik(data: ISredniaByZawodnikData): Promise<NajlepszeByZawodnikModel[]>;
    getBestResultsOfYear(): Promise<IGetBestOfTheYear[]>;
    getBestResultsOfYearJunior(): Promise<IGetBestOfTheYear[]>;
    getBestResultsOfYearLJunior(): Promise<IGetBestOfTheYear[]>;
    getBestResultsOfYearMlodzik(): Promise<IGetBestOfTheYear[]>;
    getStatsZawodnicyPerKlub(): Promise<IGetZawodnicyPerKlubResult[]>;
    getStatsZawodnicy(): Promise<IGetStatNumberResult[]>;
    getStatsZawody(): Promise<IGetStatNumberResult[]>;
    getStatsWyniki(): Promise<IGetStatNumberResult[]>;
    getKlub(data: IKlubData): Promise<IGetZawodnicyKlub[]>;
    getImagesByZawodnik(id: number): Promise<IZawodnikImageModel[]>;
    getPpnMvpJunMl(data: IGetMvpRequest): Promise<IMvpDataRow[]>;
}

class ZawodyClient extends BaseClient implements IZawodyClient {
    public getAllZawody(): Promise<IZawodyModel[]> {
        return this.request()
            .get('/zawody/')
            .then(response => response.data as IZawodyModel[]);
    }
    public getZawody(id: number): Promise<IZawodyModel> {
        const request = {
            id: id,
        };
        return this.request()
            .get('/zawody/' + id)
            .then(response => response.data as IZawodyModel);
    }
    public getZawodnik(id: number): Promise<IZawodnikModel> {
        const request = {
            id: id,
        };
        return this.request()
            .get('/zawodnik/' + id)
            .then(response => response.data as IZawodnikModel);
    }
    public getImagesByZawodnik(id: number): Promise<IZawodnikImageModel[]> {
        const request = {
            zawodnikId: id,
        };
        return this.request()
            .post('/getImagesByZawodnikId/', request)
            .then(response => response.data as IZawodnikImageModel[]);
    }
    public getWynikiByZawody(id: number): Promise<IGetWynikiResponse[]> {
        return this.request()
            .get('/wyniki?zawody.id=' + id)
            .then(response => response.data as IGetWynikiResponse[]);
    }
    public getSredniaSeriaByKonkurencja(data: ISredniaData): Promise<IGetSredniaResponse[]> {
        return this.request()
            .post('/srednia', data)
            .then(response => response.data as IGetSredniaResponse[]);
    }
    public getKlub(data: IKlubData): Promise<IGetZawodnicyKlub[]> {
        return this.request()
            .post('/klub', data)
            .then(response => response.data as IGetZawodnicyKlub[]);
    }
    public getSredniaByZawodnik(data: ISredniaByZawodnikData): Promise<SrednieByZawodnikModel[]> {
        return this.request()
            .post('/sredniaByZawodnik/', data)
            .then(response => response.data as SrednieByZawodnikModel[]);
    }

    public getNajlepszeByZawodnik(data: ISredniaByZawodnikData): Promise<NajlepszeByZawodnikModel[]> {
        return this.request()
            .post('/najlepszeByZawodnik/', data)
            .then(response => response.data as NajlepszeByZawodnikModel[]);
    }

    public createZawody(zawody: IZawodyModel): Promise<IZawodyModel> {
        const request = {
            nazwa: zawody.nazwa,
            miejsce: zawody.miejsce,
            ranga: Number(zawody.ranga),
            data: zawody.data,
        };
        return this.request()
            .post('/zawody', request)
            .then(response => response.data as IZawodyModel);
    }
    public updateZawody(zawody: any): Promise<IZawodyModel> {
        return this.request()
            .put('/zawody/' + zawody.id, zawody)
            .then(response => response.data as IZawodyModel);
    }
    public saveCsv(wyniki: IWynikSaveModel): Promise<ISaveWynikiResponse> {
        return this.request()
            .post('/saveCsv', wyniki)
            .then(response => response.data as ISaveWynikiResponse);
    }

    public getStatsWyniki(): Promise<IGetStatNumberResult[]> {
        const request = {
            nazwa: 'TEST',
        };
        return this.request()
            .post('/statIloscWynikow/')
            .then(response => response.data as IGetStatNumberResult[]);
    }
    public getStatsZawodnicyPerKlub(): Promise<IGetZawodnicyPerKlubResult[]> {
        const request = {
            nazwa: 'TEST',
        };
        return this.request()
            .post('/statIloscZawodnikowPerKlub/')
            .then(response => response.data as IGetZawodnicyPerKlubResult[]);
    }
    public getStatsZawodnicy(): Promise<IGetStatNumberResult[]> {
        const request = {
            nazwa: 'TEST',
        };
        return this.request()
            .post('/statIloscZawodnikow/')
            .then(response => response.data as IGetStatNumberResult[]);
    }
    public getStatsZawody(): Promise<IGetStatNumberResult[]> {
        const request = {
            nazwa: 'TEST',
        };
        return this.request()
            .post('/statIloscZawodow/')
            .then(response => response.data as IGetStatNumberResult[]);
    }

    public getBestResultsOfYear(): Promise<IGetBestOfTheYear[]> {
        const request = {
            nazwa: 'TEST',
        };
        return this.request()
            .post('/bestResultsOfYear/')
            .then(response => response.data as IGetBestOfTheYear[]);
    }
    public getBestResultsOfYearJunior(): Promise<IGetBestOfTheYear[]> {
        const request = {
            nazwa: 'TEST',
        };
        return this.request()
            .post('/bestResultsOfYearJunior/')
            .then(response => response.data as IGetBestOfTheYear[]);
    }
    public getBestResultsOfYearLJunior(): Promise<IGetBestOfTheYear[]> {
        const request = {
            nazwa: 'TEST',
        };
        return this.request()
            .post('/bestResultsOfYearLJunior/')
            .then(response => response.data as IGetBestOfTheYear[]);
    }
    public getBestResultsOfYearMlodzik(): Promise<IGetBestOfTheYear[]> {
        const request = {
            nazwa: 'TEST',
        };
        return this.request()
            .post('/bestResultsOfYearMlodzik/')
            .then(response => response.data as IGetBestOfTheYear[]);
    }
    public getPpnMvpJunMl(data: IGetMvpRequest): Promise<IMvpDataRow[]> {
        return this.request()
            .post('/getMvpJunMlPPN/', data)
            .then(response => response.data as IMvpDataRow[]);
    }
}

export const statystykiClient = (): IZawodyClient => {
    return new ZawodyClient();
};
