import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import IGetBestOfTheYear from '../../api/zawody/model/GetBestOfTheYearResult';

import { renderLista } from '../shared/utils';

const Top10Junior: React.FC = () => {
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [wynikiData, setwynikiData] = useState<IGetBestOfTheYear[]>();

    const getWyniki = () => {
        setLoaderVisible(true);
        statystykiClient()
            .getBestResultsOfYearJunior()
            .then((result: IGetBestOfTheYear[]) => {
                setwynikiData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        getWyniki();
    }, []);

    const renderLista1 = () => {
        return renderLista(wynikiData);
    };

    return (
        <>
            <Container className="Panel">
                <div className="NazwaZawodow">Top 10 wyników z tego roku w kategorii junior </div>
                <Row xs={3} sm={3} md={3} lg={3}>
                    {renderLista1()}
                </Row>
            </Container>
            <ToastContainer />
        </>
    );
};

export default Top10Junior;
